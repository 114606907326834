import React from 'react'
import'../../styles/Whatsapp.css'

function Whatsapp() {
    return (
        <div className='Whatsapp'>
            <a href="https://wa.me/+918089134777" target="_blank" rel="noopener noreferrer">
                <img src="img/whatsapp-icon.png" alt="whatsapp icon" />
            </a>
        </div>
    )
}

export default Whatsapp