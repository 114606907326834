import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Offers from './Pages/Offers';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import Contact from './Pages/Contact';
import Whatsapp from './Components/Whatsapp_btn/Whatsapp';
import Gallery from './Pages/Gallery';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Whatsapp/>
        <Routes>

          <Route path='/' element={<Home />} />
          <Route path='/offers' element={<Offers />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/gallery' element={<Gallery />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
