import React, { useEffect, useState } from 'react'
import axios from 'axios'
import '../../styles/Offersection.css'
import LazyLoad from 'react-lazyload';

function Offersection() {

  const [offers, setOffer] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All')
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const wordpressUrl = 'https://www.live7supermarket.com/server/'
    axios.get(`${wordpressUrl}wp-json/wp/v2/offer`)
      .then(res => {
        setOffer(res.data);
        setFilteredOffers(res.data)
        setIsLoading(false);
      })
      .catch(error => {
        // console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, [])



  const filterOffersByCategory = (category) => {
    if (category === 'All') {
      setFilteredOffers(offers);
    } else {
      const filtered = offers.filter(offer => offer.acf.category === category);
      setFilteredOffers(filtered);
    }
    setSelectedCategory(category);
  };
  return (
    <div className='Offersection'>
      <div className="container">
        <div className="category-buttons">
          <button onClick={() => filterOffersByCategory('All')} className={selectedCategory === 'All' ? 'active' : 'not-active'}>All</button>
          <button onClick={() => filterOffersByCategory('Vegetables')} className={selectedCategory === 'Vegetables' ? 'active' : 'not-active'}>Vegetables</button>
          <button onClick={() => filterOffersByCategory('Fruits')} className={selectedCategory === 'Fruits' ? 'active' : 'not-active'}>Fruits</button>
          <button onClick={() => filterOffersByCategory('Other')} className={selectedCategory === 'Other' ? 'active' : 'not-active'}>Other</button>
        </div>
        <div className="card">
          {isLoading ? (
            <div className="loader">
              <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : filteredOffers.length === 0 ? (
            <div className='no-results-message'>
              <h3>No products in this category.</h3>
            </div>
          ) : (filteredOffers.map(offer => (
            <LazyLoad
              key={offer.id}
              height={200} // Set the height of the placeholder
              offset={100} // Set the offset before loading
            >
              <div className="offer-item">
                <div className="image">
                  {offer.acf.product_img && (
                    <img src={offer.acf.product_img.url} alt={offer.acf.product_img.alt} />
                  )}
                  {offer.acf.discount_percentage && (
                    <span className="discount-percentage">
                      <p>{offer.acf.discount_percentage}
                        <br />
                        Off
                      </p>

                    </span>
                  )}
                </div>
                {offer.title.rendered && (
                  <h2>{offer.title.rendered}</h2>
                )}
                <div className="price">

                  {offer.acf.price && (
                    <p className='offer-price'>Rs.{offer.acf.price}</p>

                  )}
                  {offer.acf.previous_price && (
                    <p className='previous-price'>Rs.{offer.acf.previous_price}</p>
                  )}
                </div>
              </div>
            </LazyLoad>
          )))}


        </div>
      </div>
    </div>
  );
}

export default Offersection