import { createContext, useState } from "react";

const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [navbar, setnavbar] = useState(false);
    const[image, setImage] = useState('img/live7_logo.png')


    const handleToggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    
    const handleNavbar = () => {
        if (window.scrollY > 260) {
            setnavbar(true)
        } else {
            setnavbar(false)
        }
    }
    window.addEventListener('scroll', handleNavbar)



    return (
        <MenuContext.Provider value={{menuOpen,navbar, handleToggleMenu,handleNavbar,image,setImage}}>
            {children}
        </MenuContext.Provider>
    );

}
export default MenuContext;