import React from 'react'
import Offersection from '../Components/Offersection/Offersection'
import OfferBanner from '../Components/OfferBanner/OfferBanner'
import Footer from '../Components/Footer/Footer'

function Offers() {
  return (
    <div className='Offers'>
      <OfferBanner/>
      <Offersection/>
      <Footer/>
    </div>
  )
}

export default Offers