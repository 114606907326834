import React from 'react'
import '../../styles/AboutSection.css'

function AboutSection() {
  return (
    <div className='AboutSection'>
      <div className="container">
        <h1 data-aos="fade">About Us</h1>
        <div className="row">
          <div className="left-col custom-fade-right"data-aos="custom-fade-right"data-aos-duration="1000">
            <div className="content">
              <img src="img/DSC06990_resized.jpg" alt="super market" />
              <div className="background-box"></div>
            </div>
          </div>
          <div className="right-col custom-fade-left"data-aos="custom-fade-left"data-aos-duration="1000">
            <div className="content">
              <h2><span>Bringing Joy to Shopping:</span> Unveiling a Universe of Products!</h2>
              <p><span>Our Vision</span> is to establish a brand synonymous with trust, celebrated for its unparalleled quality, unwavering commitment to customer s the embodiment of a premium shoppin achieved through operational excellence.<br/><br/>
              <span>Our Mission</span> is to establish a network of interconnected chains that exemplify unwavering trust, reinforce our distinctive brand essence, and propel our growth into diverse geographical regions.
              </p>
            </div>

          </div>
        </div>


      </div>
    </div>
  )
}

export default AboutSection