import React, { useEffect, useState } from 'react';
import '../../styles/PhotoGallery.css';
import lozad from 'lozad';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

function PhotoGallery() {
    useEffect(() => {
        const observer = lozad('.lozad', {
            loaded: function(el) {
                
                el.classList.add('lazy-loaded'); 
                el.addEventListener('load', () => {
                    el.classList.remove('skeleton-loader');
                });
            },
            rootMargin: '-20px 0px',
        });
        observer.observe();

        return () => {
           
        };
    }, []);

    const onInit = () => {
        console.log('lightGallery has been initialized');
    };

    return (
        <div className='PhotoGallery'>
            <div className="container" data-aos="fade" data-aos-offset="0" data-aos-duration="1000">
                <LightGallery onInit={onInit} speed={500}>
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC06902.jpg" alt="img1" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC06910.jpg" alt="img2" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC06935.jpg" alt="img3" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC06891.jpg" alt="img4" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC06948.jpg" alt="img5" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC06962.jpg" alt="img6" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC06990.jpg" alt="img7" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC07135.jpg" alt="img8" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC07216.jpg" alt="img9" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC07235.jpg" alt="img10" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC07237.jpg" alt="img12" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC07238.jpg" alt="img13" />
                    <img className="lozad skeleton-loader" data-src="img/gallery_pics/DSC07245.jpg" alt="img14" />
                </LightGallery>
            </div>
        </div>
    );
}

export default PhotoGallery;
