import React from 'react'
import '../../styles/Herosection.css'
import Navbar from '../Navbar/Navbar'

function Herosection() {
    return (
        <div className='Herosection'>
            <Navbar />
            <div className="container">
                <div className="content custom-fade-right" data-aos="custom-fade-right" data-aos-duration="1000">
                    <h2>Leading <span class="cherpulassery">Cherpulassery</span> through convenience and quality is <span className='highlight'>Live7 Supermarket</span></h2>
                    <p>The value for which you have been spending your hard-earned money is being restored by Live7. Our goal is to provide you with high-quality products at affordable prices for anything you may possibly need. From now on, let Cherpulassery's best feature be its quality!</p>
                    <a href="/offers">Show Offers</a>
                </div>
                <div className="image custom-fade-left" data-aos="custom-fade-left" data-aos-duration="1000">
                    <img className='hero-section-img' src="img/AdobeStock_246027822_Preview-removebg-preview.png" alt="" />
                    <div className='card custom-slide-left'data-aos="custom-slide-left" data-aos-duration="6000"data-aos-offset="0">
                        <img src="img/quality-guarenteed.png" alt="" />
                        <p >Quality Guaranteed</p>
                    </div>
                    <div className='card custom-slide-left-deals'data-aos="custom-slide-left-deals" data-aos-duration="6000"data-aos-offset="0">
                    <img src="img/best-deals.png" alt="" />

                        <p >Best Deals</p>
                    </div>
                </div>
            </div>
            <div className="gradient"></div>
        </div>
    )
}

export default Herosection