import React, { useEffect,useContext } from 'react';
import '../../styles/ContactBanner.css'
import Navbar from '../Navbar/Navbar';
import MenuContext from '../../Context/menuProvider'





function ContactBanner() {
    const {image,setImage,navbar} = useContext(MenuContext);



    useEffect(() => {
        if(navbar == true){
            setImage("img/live7_logo.png")
        }else{
            setImage("img/live7-white-logo.png")
        }
      });
    return (
        <div className='ContactBanner'>
            <Navbar />
            <div className="container">
                <img src="img/DSC06902_resized.jpg" alt="" />
            </div>
            <div className="overlay">

            </div>
            <div className="text">
                <h2>Contact</h2>
            </div>

        </div>
    )
}

export default ContactBanner
