import React from 'react'
import PhotoGallery from '../Components/PhotoGallery/PhotoGallery'
import Footer from '../Components/Footer/Footer'
import GalleryBanner from '../Components/GalleryBanner/GalleryBanner'

function Gallery() {
    return (
        <div className='Gallery'>
            <GalleryBanner />
            <PhotoGallery />
            <Footer />
        </div>
    )
}

export default Gallery