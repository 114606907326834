import React from 'react'
import Herosection from '../Components/Herosection/Herosection'
import Whychoose from '../Components/Whychoose/Whychoose'
import AboutSection from '../Components/AboutSection/AboutSection'
import Footer from '../Components/Footer/Footer'

function Home() {
  return (
    <div className='Home'>
        <Herosection/>
        <Whychoose/>
        <AboutSection/>
        <Footer/>
        
    </div>
  )
}

export default Home