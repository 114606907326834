import React, { useEffect,useContext } from 'react';
import '../../styles/GalleryBanner.css'
import Navbar from '../Navbar/Navbar';
import MenuContext from '../../Context/menuProvider'





function GalleryBanner() {
    const {image,setImage,navbar} = useContext(MenuContext);



    useEffect(() => {
        if(navbar == true){
            setImage("img/live7_logo.png")
        }else{
            setImage("img/live7-white-logo.png")
        }
      });
    return (
        <div className='GalleryBanner'>
            <Navbar />
            <div className="container">
                <img src="img/DSC06902_resized.jpg" alt="live7 supermarket" />
            </div>
            <div className="overlay">

            </div>
            <div className="text">
                <h2>Gallery</h2>
            </div>

        </div>
    )
}

export default GalleryBanner