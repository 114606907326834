import React, { useContext, useEffect, useState } from 'react'
import '../../styles/Navbar.css'
import MenuContext from '../../Context/menuProvider'
import { Link, useLocation } from 'react-router-dom';




function Navbar() {
    const { menuOpen, handleToggleMenu, navbar, handleNavbar, image,setImage } = useContext(MenuContext);
    const location = useLocation();
    const handleMenuItemClick = () => {
        if (window.innerWidth < 576) {
            handleToggleMenu();
        }
    };
   



    const shouldReload = (path) => {
        // Check if the clicked link matches the current location
        return location.pathname === path;
      };
    return (
        <div className='Navbar'>
            <header className={navbar ? 'header active' : 'header'}>
                <div className="logo">
                    <a href="/"><img src={image} alt="live7 supermarket logo" /></a>
                </div>
                <nav className='nav-menu'>
                    <ul className={menuOpen ? 'nav-links active' : 'nav-links'}>
                        <li>
                            <Link onClick={()=>{ handleMenuItemClick(); shouldReload('/') && window.location.reload();setImage('img/live7_logo.png');}}to="/">Home</Link>
                            <hr />
                        </li>
                        <li>
                            <Link onClick={()=>{ handleMenuItemClick(); shouldReload('/offers') && window.location.reload();}} to="/offers">Offers</Link>
                            <hr />
                        </li>
                        <li>
                            <Link onClick={()=>{ handleMenuItemClick(); shouldReload('/gallery') && window.location.reload();}} to="/gallery">Gallery</Link>
                            <hr />
                        </li>
                        <li>
                            <Link onClick={()=>{ handleMenuItemClick(); shouldReload('/contact') && window.location.reload();}} to="/contact">Contact</Link>
                            <hr />
                        </li>
                    </ul>
                    <div className="menu-button">
                        <label htmlFor="check">

                            <input type="checkbox" id="check" onClick={handleToggleMenu} />
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                    </div>
                </nav>

            </header>
        </div>
    )
}

export default Navbar