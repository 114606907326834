import React from 'react'
import '../../styles/Whychoose.css'

function Whychoose() {
    return (
        <div className='Whychoose'>
            <div className="container">
                <h2 data-aos="fade">Why Choose us</h2>
                <div className="row">
                    <div className="col">
                        <div className="card custom-fade-right"data-aos="custom-fade-right"data-aos-duration="1000">
                            <div className='icon'>
                                <img src="img/quality-product.png" alt="" />
                            </div>
                            <div className="content">
                                <h3>QUALITY PRODUCTS</h3>
                                <p>Emphasize the high quality and freshness of your supermarket's products, ensuring customers get the best value for their money.</p>
                            </div>

                        </div>
                        <div className="card custom-fade-right"data-aos="custom-fade-right"data-aos-duration="1000">
                            <span className='icon'>
                                <img src="img/wide-variety.png" alt="wide variety" />
                            </span>
                            <div className="content">
                                <h3>WIDE VARIETY</h3>
                                <p>Highlight the extensive range of products available, catering to diverse tastes and needs, making it a one-stop-shop for all their grocery needs.</p>
                            </div>

                        </div>

                    </div>
                    <div className="img-col"data-aos="fade">
                        <img src="img/supermarket_illustration.png" alt="" />
                    </div>
                    <div className="col">
                        <div className="card custom-fade-left"data-aos="custom-fade-left"data-aos-duration="1000">
                            <div className='icon'>
                                <img src="img/convenience.png" alt="" />
                            </div>
                            <div className="content">
                                <h3>CONVENIENCE</h3>
                                <p>Emphasize the convenience of shopping at your physical store, including easy access, ample parking, and a well-organized layout.</p>
                            </div>

                        </div>
                        <div className="card custom-fade-left"data-aos="custom-fade-left"data-aos-duration="1000">
                            <span className='icon'>
                                <img src="img/customer-support.png" alt="" />
                            </span>
                            <div className="content">
                                <h3>CUSTOMER SERVICE</h3>
                                <p>Highlight your excellent customer service, including knowledgeable and friendly staff who provide a welcoming shopping experience.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Whychoose