import React from 'react'
import '../../styles/Map.css'

function Map() {
  return (
    <div className='Map'>
        <div className="container">

        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7394.697054664983!2d76.30428420554453!3d10.877758600311518!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba7d1547d23ca83%3A0x869c2f4f9643e71a!2sLive7%20Supermarket!5e0!3m2!1sen!2sin!4v1693826141046!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
  )
}

export default Map