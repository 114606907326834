import React, { useContext } from 'react'
import '../../styles/Footer.css'
import MenuContext from '../../Context/menuProvider'
import { Link, useLocation } from 'react-router-dom';

function Footer() {
    const { menuOpen, handleToggleMenu, navbar, handleNavbar, image, setImage } = useContext(MenuContext);
    const location = useLocation();
    const handleMenuItemClick = () => {
        if (window.innerWidth < 576) {
            if(menuOpen === true){
                handleToggleMenu();
            }
            
        }
    };




    const shouldReload = (path) => {
        // Check if the clicked link matches the current location
        return location.pathname === path;
    };
    return (
        <div className='Footer'>
            <div className="container">
                <div className="logo">
                    <img src="img/live7_logo.png" alt="" />
                </div>
                <div className="links">
                    <div className="section">
                        <ul>
                            <li>
                                <Link onClick={() => { handleMenuItemClick(); shouldReload('/') && window.location.reload(); setImage('img/live7_logo.png'); }} to="/">Home</Link>
                                <hr />
                            </li>
                            <li>
                                <Link onClick={() => { handleMenuItemClick(); shouldReload('/offers') && window.location.reload(); }} to="/offers">Offers</Link>
                                <hr />

                            </li>
                            <li>
                                <Link onClick={() => { handleMenuItemClick(); shouldReload('/gallery') && window.location.reload(); }} to="/gallery">Gallery</Link>
                                <hr />

                            </li>
                            <li>
                                <Link onClick={() => { handleMenuItemClick(); shouldReload('/contact') && window.location.reload(); }} to="/contact">Contact</Link>
                                <hr />

                            </li>

                        </ul>
                    </div>


                </div>
                <div className="address">
                    <div className="details">

                        <p><i className="fa-solid fa-location-dot"></i>Kavuvattom, Pattambi</p>
                        <p className='addrs_txt'>Cherpulassery, Kerala</p>
                        <p className='addrs_txt'>679503</p>
                    </div>
                    <div className="social-media">
                        <a href="https://www.facebook.com/live7supermarket" target="_blank" rel="noopener noreferrer"><span className="social-media"><i
                            className="fa-brands fa-facebook"></i></span></a>
                        <a href="https://instagram.com/live7supermarket?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer"><span className="social-media"><i
                            className="fa-brands fa-instagram"></i></span></a>
                        <a href="mailto:info@live7supermarket.com"><span className="social-media"><i class="fa-solid fa-envelope"></i></span></a>

                    </div>

                </div>
            </div>

        </div>
    )
}

export default Footer