import React from 'react'
import '../../styles/GetInTouch.css'

function GetInTouch() {
    return (
        <div className='GetInTouch'>
            <div className="container">
                <h2 data-aos="fade">Get In Touch!</h2>
                <div className="card-container">
                    <div className="card"data-aos="fade">
                        <div className="icon">
                            <img src="img/address.png" alt="address icon" />
                        </div>
                        <div className="content">
                            <p>
                            Live 7 Pvt Ltd.<br/>
Kavuvattam, Pattambi Road,<br/> Cherpulassery - 679503

                            </p>
                        </div>
                    </div>
                    <div className="card"data-aos="fade">
                        <a href="#">
                            <div className="icon">
                                <img src="img/phone.png" alt="phone icon" />
                            </div>
                            <div className="content">
                                <a className='link' href="tel:+91 8089 134 777">+91 8089 134 777</a>
                            </div>
                        </a>
                    </div>
                    <div className="card"data-aos="fade">
                        <a href="#">
                            <div className="icon">
                                <img src="img/mail.png" alt="mail icon" />

                            </div>
                            <div className="content">
                                <a className='link email' href="mailto:info@live7supermarket.com">info@live7supermarket.com</a>
                            </div>
                        </a>
                    </div>


                </div>

            </div>

        </div>
    )
}

export default GetInTouch